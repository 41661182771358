/* global Component */
class appComponent extends Component {

    static name() {
        return "appComponent";
    }

    static componentName() {
        return "appComponent";
    }

    getProps() {
        return ['categoryString','categoryName'];
    }

    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="container-fluid">
                      <div class="section-main">
                            <div class="row">
                              <div class="col-sm-3 col-md-2 col-xs-12 mb-2 mb-sm-0 pt-0 px-2 pb-2 p-sm-2 pl-xl-3 pr-xl-4 filters-section">
                                  <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                  <categorySectionComponent :categories=false></categorySectionComponent>
                              </div>
                              <div class="col-sm-9 col-md-10 col-xs-12 pt-2 pt-sm-0 px-0 px-md-3">
                                  <div class="col-12">
                                    <itemsSectionComponent :items="$store.items" :items_in_car="$store.items_in_car" :cat_history="$store.cat_history"></itemsSectionComponent>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

appComponent.registerComponent();